check the code and adapt it for smaller windows(mobile and tablet)

<template>
    <div class="deposit-box">
        <header class="header">
            <div class="currency-box"><img src="@/assets/images/matic-logo.png" alt="usdt-coin"><span
                    class="usdt-coin">{{
                        $t('matic') }}</span>
                <span>(Polygon network)</span>
            </div>
            <div class="address-box" @click="copyAddress">
                <span class="address-truncate">{{ firstPartAddress }}</span><span class="address-end">{{
                        secondPartAddress
                    }}</span>
                <img src="@/assets/images/copy.svg" alt="copy">
            </div>
        </header>
        <div class="inner-info">
            <p class="text-info">
                <span>{{ $t('notice') }}</span>: <span class="desc">{{ $t('wallet-modal.deposit.desc')
                    }}</span>
            </p>
            <div class="qrcode"><vue-qrcode type="image/png" maskPattern="4" :value="address" version="5" /></div>
        </div>
        <wallet-modal-tab-footer />
        <the-snackbar />
    </div>
</template>

<script setup>
import { onMounted, ref, provide, onUnmounted, watchEffect, computed } from 'vue'
import VueQrcode from 'vue-qrcode'

import { useAuthStore } from '@/stores/authStore'
import WalletModalTabFooter from './WalletModalTabFooter/WalletModalTabFooter.vue'
import TheSnackbar from '@/components/common/Snackbar/AppSnackbar.vue'

const { getAccounts } = useAuthStore()

const address = ref('')
const firstPartAddress = ref('')
let secondPartAddress = ref('')

const timeoutTransition = ref(3000)

const showSnackbar = ref(false)

const snackbarMode = ref('success')

const snackbarMessage = ref('text copied')

const onShowSnackbar = () => {
    showSnackbar.value = !showSnackbar.value

    setTimeout(() => {
        showSnackbar.value = !showSnackbar.value
    }, timeoutTransition.value)
}

provide('snackbarData', { snackbarMessage, snackbarMode, showSnackbar, onShowSnackbar })

async function copyAddress() {
    try {
        await navigator.clipboard.writeText(address.value)
        console.log('Content copied to clipboard')
        onShowSnackbar()
    } catch (err) {
        console.error('Failed to copy: ', err)
    }
}

onMounted(async () => {
    address.value = (await getAccounts())[0]
    const delta = Math.floor(address.value.length / 2)
    firstPartAddress.value = address.value.substring(0, delta)
    secondPartAddress.value = address.value.substring(delta)
})

const windowWidth = ref(window.innerWidth)

// A function to truncate the address dynamically based on the window width
function truncateAddress(address, width) {
    if (width > 1024) {
        // full address for large screens
        return address
    } else {
        // dynamic truncation for smaller screens
        const visibleChars = Math.max(10, Math.floor(width / 30)) // adjust as needed
        const ellipsis = '...'

        return `${address.slice(0, visibleChars)}${ellipsis}${address.slice(-visibleChars)}`
    }
}

const truncatedAddress = computed(() => truncateAddress(address.value, windowWidth.value))

// Watch for window resize events to update the truncated address
const onResize = () => {
    windowWidth.value = window.innerWidth
}

onMounted(() => {
    window.addEventListener('resize', onResize)
})

onUnmounted(() => {
    window.removeEventListener('resize', onResize)
})

watchEffect(() => {
    // This will run whenever the `windowWidth` ref changes
    truncatedAddress.value = truncateAddress(address.value, windowWidth.value)
})
</script>

<style scoped>
.deposit-box {
    width: 100%;
    display: flex;
    flex-direction: column;

    .header,
    .currency-box,
    .address-box {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}

.header {
    margin-bottom: 25px;
    flex-direction: column;
    width: 100%;
}

.currency-box {
    background: #212f72;
    width: 100%;
    padding: 12px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #bec9f4;
    column-gap: 8px;


    img {
        width: 28px;
        height: 28px;
    }

    .usdt-coin {
        text-transform: uppercase;
        align-self: center;
    }
}

.address-box {
    width: 100%;
    cursor: pointer;
    flex-direction: row;
    background-color: #171f44;
    max-width: 100%;
    padding: 12px;
    font-weight: 700;
    font-size: 18px;
    color: #bec9f4;
    line-height: 20px;
}

.address-box img {
    height: 28px;
    margin-left: 8px;
}

.address-truncate {
    overflow: hidden;
    text-overflow: clip;
    /* Changed to clip */
    white-space: nowrap;
    /* Changed to nowrap */
}

.inner-info {
    width: calc(100% - 2px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.text-info {
    font-family: "Roboto", sans-serif;
    font-size: clamp(16px, 1.5vw, 21px);
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #6b7fc8;
    max-width: 100%;
    margin-bottom: 16px;

    span:nth-child(1) {
        font-weight: 600;
        text-transform: uppercase;
    }
}

.qrcode {
    padding: 10px;
    background-color: #2e3b70;
    height: 200px;
}

.address-truncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (min-width: 1500px) {
    .header {
        align-items: stretch;
        flex-direction: row;
    }

    .currency-box {
        img {
            height: 28px;
            width: 28px;
        }
    }

    .currency-box,
    .address-box {
        width: 100%;
        margin: 0;
        padding: 12px;
        box-sizing: border-box;
        text-align: center;
    }

    .address-box {
        display: flex;
        align-items: center;
    }

    .inner-info {
        flex-direction: row;
    }

    .text-info {
        width: 70%;
        margin-bottom: 0;
    }

    .qrcode {
        margin-top: 0;
        padding: 1rem;

        img {
            height: 100%;
        }
    }
}
</style>